import {  useEffect } from "react";

import rotatedevice from "../src/assets/img/rotatedevice.gif";
import { Image } from "semantic-ui-react";
import logo from "../src/assets/img/logo.png";

function RotateScreen() {

  useEffect(() => {



    return () => {

    };
  }, []);




  return (
    <>
      <div className="rotatedevice"> 
      <div className="rotatedeviceimg normaltxt">
      <Image size="massive" src={rotatedevice} />
      
      <br/>OBRÓĆ EKRAN POZIOMO <div style={{color:"grey",marginTop:20,fontSize:15}}>ODBLOKUJ OBRACANIE EKRANU</div>
      <Image  centered src={logo} style={{marginTop:50,opacity:"1",width:150}} />
        </div>
      </div>
    </>
  );
}

export default RotateScreen;
