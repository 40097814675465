import axios from '../axios';

export function ConnectWS(method,tokendata, params, callback) {

  // const data = {};
  // const paramstmp = new URLSearchParams(params).toString();
  // method=method+"?"+paramstmp;
  let start = new Date().getTime();
  if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
    console.log("PYTAM - " + start + " | TOKEN:",tokendata ? "tak" : "nie"," | ", method, JSON.stringify(params))
  }
  if (tokendata) {
    let tokendata1 = "Bearer " + tokendata;
    axios.defaults.headers.common['Authorization'] = tokendata1;
  }else{
    axios.defaults.headers.common['Authorization'] = null;
  }


  const json = JSON.stringify(params);
  axios.post(method, json, {
    headers: {
      // Overwrite Axios's automatically set Content-Type
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
        let end = new Date().getTime();
        let time = end - start;
        console.log("ODP - " + start + " Czas: " + msToTime(time) + " | ", method, response)
      }

      callback(response,0);
    }).catch(err => {
      
      if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
        let end = new Date().getTime();
        let time = end - start;
        console.log("ODP[ERR] - " + start + " Czas: " + msToTime(time) + " | ", method)
      }
      try { 
        if (err.response) {
          // Żądanie wysłane i serwer odpowiedział
          console.log('error.response', err.response);
          if (err.response.status === 401) {
            callback(err.response.data.error, 1);
          } else if (err.response.status === 404) {
            callback("Brak metody w WS-ie E404");
          } else {
            if(err.response.data.error){
              callback(err.response.data.error.toString());
            }else{
              callback(err.response.data);
            }
            
          }
        } else if (err.request) {
          // Żądanie zostało wysłane, ale nie otrzymano odpowiedzi
          console.log('error.request', err.request);
          callback("Error - WS - zobacz konsole: error.request", 2);
        } else {
          // Podczas konfigurowania żądania wystąpił błąd
          console.log('error.message', err.message);
          callback("Error - WS - zobacz konsole: error.message", 2);
        }
      } catch (err) {
        callback("Error - WS API", 2);
      }

    })
}


function msToTime(duration) {
  var milliseconds = parseInt((duration % 1000))
    , seconds = parseInt((duration / 1000) % 60)
    , minutes = parseInt((duration / (1000 * 60)) % 60)
    , hours = parseInt((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
}



