import React, { useEffect, useState, useContext } from 'react';
import { Grid, Segment, Icon, Container, Message } from 'semantic-ui-react'

import { useParams, useHistory } from "react-router-dom";
import AuthContext from '../context/authContext';
import { ConnectWS } from '../ConnectWS/ConnectWS';

function ConfirmPage() {
  const { methodname, methodtoken } = useParams()
  const [methodStatus, setmethodStatus] = useState(null)
  const authContext = useContext(AuthContext);// eslint-disable-line no-unused-vars
  let history = useHistory();
  const [loading, setloading] = useState(false);

  useEffect(() => {
    prepareMethod()
    return () => {

    };
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  function prepareMethod() {
    if (methodname === "confirmEmail") {
      setloading(true)
      ConnectWS("/confirmEmail", null, { token: methodtoken }, function (response, logout) {

        if (logout === 1) {

        } else if (response && response.status && Number(response.status) === 200) {
          setmethodStatus({ text: response.data.message, statusNegative: false, statusPositive: true })
        } else {
          setmethodStatus({ text: response, statusNegative: true, statusPositive: false })
        }
        setloading(false)
        setTimeout(function () { history.push("/") }, 5000);
      });

  

    }  else {
      history.push("/404")
    }

  }



  return (
    <Grid stackable style={{ height: "100%", margin: 0, marginTop: 10 }}>
    <Grid.Row className="bgCenterPanel" style={{ margin: 0, padding: 0 }} >


      <Grid.Column width={16} >
        <Container textAlign='center' style={{ marginTop: 50 }}>

          <Segment basic loading={loading ? true : false}>
          
            
            <Message size='large' icon positive={methodStatus ? methodStatus.statusPositive : false} negative={methodStatus ? methodStatus.statusNegative : false}>
              <Icon name={methodStatus ? methodStatus.statusPositive ? "thumbs up" : "exclamation circle" : ""} />
            
              <Message.Content>
                <Message.Header>{methodStatus ? methodStatus.statusPositive ? "GRATULACJE" : methodStatus.statusNegative ? "PROBLEM" : "INFORMACJA" : ''}</Message.Header>
                <p>
                  {methodStatus ? methodStatus.text : ""}
                </p>
              </Message.Content>

            </Message>
          </Segment>
        </Container>
      </Grid.Column>


    </Grid.Row>
  </Grid>
  );
}

export default ConfirmPage;
