import {  useEffect,useContext } from "react";


import { Button, ButtonGroup, Container, Image } from "semantic-ui-react";
import logo from "../../src/assets/img/logo.png";
import AuthContext from "../context/authContext";

function StartPage() {

  const authContext = useContext(AuthContext);
  
  useEffect(() => {



    return () => {

    };
  }, []);// eslint-disable-line react-hooks/exhaustive-deps




  return (
    <Container textAlign="center">

    
    
      <p style={{lineHeight:"16px",textAlign:"left"}}>
      <Image src={logo} style={{width:60}} floated='right' />
      <b><span style={{fontSize:16}}>W</span>itaj</b> w aplikacji przeglądarkowej do wspomagania pielęgnacji jamy ustnej!
      Tutaj błyszczące ząbki są celem, a Ty jesteś głównym bohaterem.
      Nasza aplikacja przeglądarkowa sprawi, że mycie zębów stanie się nie tylko efektywne, ale także przyjemne, Ty myjesz a dziecko skupia uwagę na obrazku.
     
      </p>
      
        <ButtonGroup widths='2' size="mini" >
          <Button style={{marginRight:5}} primary onClick={()=>authContext.changeView("Login")}>Mam już konto</Button>
          <Button color="orange" onClick={()=>authContext.changeView("Register")}>Załóż konto</Button>
        </ButtonGroup>

    
    </Container>
  );
}

export default StartPage;
