import React, { useState, useEffect, useContext } from 'react';
import { Form, Segment, Button, Container } from 'semantic-ui-react'
import useAuth from '../hooks/useAuth';
import { ConnectWS } from '../ConnectWS/ConnectWS';
import AuthContext from '../context/authContext';
import { useToasts } from 'react-toast-notifications';
import { Link } from "react-router-dom";


function Register(props) {
    const { addToast } = useToasts();
    const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
    const [isloading, setLoading] = useState(false);
    const [error, setError] = useState("");// eslint-disable-line no-unused-vars
    const authContext = useContext(AuthContext);
    const [FormE, setFormE] = useState({ username: "", password: "", password2: "", regulamin: true });

    const submit = e => {
        setError("");
        let username = ""
        let password = ""

        username = FormE.username;
        password = FormE.password;

        if (FormE.password !== FormE.password2) {
            addToast("Wpisane hasła sa różne", { appearance: 'error' });
            return
        }

        setLoading(true);

        ConnectWS("/registerAccount", null, { email: username, password: password }, function (response, logout) {
            if (logout === 1) {

            } else if (response && response.status && Number(response.status) === 200) {

                addToast(response.data.message, { appearance: 'info' });
                setLoading(false);
                setFormE({ username: "", password: "", password2: "", regulamin: false })

            } else {
                setLoading(false);
                setAuth(false);
                setError(response);
            }


        });




    }




    useEffect(() => {


        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, []);// eslint-disable-line react-hooks/exhaustive-deps






    //
    return (
        <>


            <Segment color="orange" className='loginSemgent'>


                <Form size='mini' style={{ marginTop: 5 }} onSubmit={submit} autoComplete="off">
                    <Form.Input fluid type="email" icon='user' name='username' value={FormE.username} onChange={(event, data) => setFormE({ ...FormE, username: data.value })} required iconPosition='left' placeholder='Email' />
                    <Form.Input
                        fluid
                        icon='lock'
                        iconPosition='left'
                        placeholder='Hasło'
                        type='password'

                        name='Hasło'
                        value={FormE.password}
                        onChange={(event, data) => setFormE({ ...FormE, password: data.value })}
                        //value={cookies.UserData ? cookies.UserData.password : ""}
                        required
                    />
                    <Form.Input
                        fluid
                        icon='lock'
                        iconPosition='left'
                        placeholder='Potwierdź Hasło'
                        type='password'

                        name='Potwierdź Hasło'
                        value={FormE.password2}
                        onChange={(event, data) => setFormE({ ...FormE, password2: data.value })}
                        //value={cookies.UserData ? cookies.UserData.password : ""}
                        required
                    />
                    {/* 
                    <Form.Group inline style={{ lineHeight: "12px" }}>
                        <Form.Field>
                            <Form.Checkbox onChange={(e, data) => setFormE({ ...FormE, regulamin: data.checked })} inline required />
                        </Form.Field>
                        <Form.Field>
                            Przeczytałem i akceptuję <Link to="regulamin" target="_blank" rel="noopener noreferrer" > regulamin</Link>,
                        </Form.Field>
                       
                    </Form.Group>
                    */}
                    
                    <Button size='mini' color="orange" fluid disabled={isloading ? true : false || FormE.regulamin === false} loading={isloading} animated='fade'>
                        <Button.Content visible>ZAREJESTRUJ SIĘ</Button.Content>
                        <Button.Content hidden>Jeszcze jeden klik i ...</Button.Content>
                    </Button>

                    {error ? (
                        <>
                            <div className="ui negative message"><div className="header">{error}</div></div>
                        </>
                    ) : ''}

                </Form>
                <Link to="regulamin" target="_blank" rel="noopener noreferrer" > Regulamin</Link>
                <Container textAlign='center' className='cursorpointer' onClick={() => authContext.changeView("Login")} style={{ marginTop: 10, textDecoration: "underline" }}>
                    Przejdź do logowania
                </Container>
            </Segment>
        </>


    );
}



export default Register;