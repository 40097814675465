import React from 'react'
import { Button, Header } from 'semantic-ui-react';

import { useHistory } from "react-router-dom";
import { changePageName } from './myfunctions/myfunctions';

function NotFound() {
  let pageName = "BŁĄD 404"
  let history = useHistory();
  changePageName(pageName)
  setTimeout(() => {
   // authContext.changeView({ myView: "" })
   // history.push("/")
  
  }, 3000);

  return (

    <div className="errorS">
      
        <div className="ui column center aligned">
        <Header  style={{fontSize:60}}>
          BŁĄD 404
        <Header.Subheader style={{fontSize:30}}>
     UPS! NIE UDAŁO SIĘ ZNALEŹĆ TEJ STRONY!
    
    </Header.Subheader>
    <Header.Subheader>
      <br/>
    Wróć na stronę główną lub skontaktuj się z nami. Pomożemy.
    </Header.Subheader>
        </Header>
        <a href="/"><Button size='large' basic>STRONA GŁOWNA</Button></a><Button onClick={()=>history.go(-2)} size='large' basic>POPRZEDNIA STRONA</Button>
        </div>
      </div>
     

  );
}

export default NotFound;