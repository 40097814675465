import React, { useEffect, useContext, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import useAuth from './hooks/useAuth';
import AuthContext from './context/authContext';

import { ConnectWS } from './ConnectWS/ConnectWS'

import SelectACharacter from './SelectACharacter';
import NotFound from './404';
import { SCezardecrypt } from './myfunctions/myfunctions';
import ConfirmPage from './Ui/ConfirmPage';
import Statue from './Ui/Statue';
import PrivacyPolicy from './Ui/PrivacyPolicy';
import useWindowDimensions from './hooks/useWindowDimensions';
import { Button, Container, Icon, Modal, ModalActions, ModalContent, ModalHeader } from 'semantic-ui-react';


function RouterPage(props) {
    const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
    const { width } = useWindowDimensions();
    const authContext = useContext(AuthContext);// eslint-disable-line no-unused-vars
    const [open, setOpen] = useState(false)

    useEffect(() => {
        checkUser()
        if (width > 1000) {
            setOpen(true)
        }

        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, []);// eslint-disable-line react-hooks/exhaustive-deps



    const checkUser = () => {

        let token = window.localStorage.getItem('token');

        if (token) {
            try {
                let userInfo = JSON.parse(SCezardecrypt(window.localStorage.getItem('userInfo')));
                console.log(userInfo)
                //authContext.changeUser(userInfo);
                //authContext.changeToken(token);       
                //setAuth(true, token, JSON.stringify(userInfo));
                ConnectWS("/login", null, { email: userInfo.email, password: userInfo.s }, function (response, logout) {

                    if (logout === 1) {
                        setAuth(false);
                    } else if (response && response.status && Number(response.status) === 200) {
                        let userInfoN = { ...response.data, s: userInfo.s, email: userInfo.email }
                        authContext.changeUserInfo(userInfoN)
                        authContext.changeToken(response.data.token);
                        setAuth(true, response.data.token, JSON.stringify(userInfoN));

                        console.log("AUTOLOGIN")
                    } else {

                    }
                });
            } catch (error) {
                console.error(error);

            }
        }


    }


    return (

        <>
            <Modal
                onClose={() => setOpen(false)}
                open={open}
                dimmer={"blurring"}


            >
                <ModalHeader><Icon name="info circle" />Informacja - został wykryty większy ekran</ModalHeader>
                <ModalContent scrolling>
                    <Container textAlign="center" >

                        <b>Wykryto, że szerokość ekranu Twojego urządzenia przekracza 1000 pikseli.</b><br />
                        Nasza aplikacja przeglądarkowa została zaprojektowana z myślą o wspieraniu procesu szczotkowania zębów u dzieci.
                        Podczas całego procesu szczotkowania, aplikacja stopniowo odsłania obrazek, który przyciąga uwagę malucha.<br />
                        <b>Zalecamy korzystanie z aplikacji na urządzeniach mobilnych</b>, takich jak smartfony i tablety, aby zapewnić najlepsze doświadczenie oraz komfort użytkowania podczas codziennej higieny.<br />

                    </Container>
                </ModalContent>
                <ModalActions >
                    <Button onClick={() => setOpen(false)}>
                       ZAMKNIJ WIADOMOŚĆ
                    </Button>

                </ModalActions>
            </Modal>


            <Switch>
                <Route exact path="/regulamin"><Statue /> </Route>
                <Route exact path="/polityka-prywatnosci"><PrivacyPolicy /> </Route>
                <Route exact path="/method/:methodname/:methodtoken"><ConfirmPage /> </Route>
                <Route exact path="/">{auth ? <SelectACharacter /> : <SelectACharacter />} </Route>

                <Route component={NotFound} />
            </Switch>
        </>
    )
}

export default RouterPage;