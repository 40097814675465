import { useState, useEffect, useContext } from "react";

import AnimatedBrushPrev from "./Animation/AnimatedBrushPrev";
import { Button, Container, Grid, GridColumn, Image, Label, Segment } from "semantic-ui-react";
import AuthContext from './context/authContext';
import { useAddToHomescreenPrompt } from "./AddToHomeScreen";
import useWindowDimensions from "./hooks/useWindowDimensions";
import { projectView } from "./myfunctions/myfunctions";
import useAuth from "./hooks/useAuth";
import SliderImages from "./SliderImages";
import SliderImagesFree from "./SliderImagesFree";
import swipe from '../src/assets/img/swipe.gif'

function SelectACharacter() {
  const authContext = useContext(AuthContext);
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();// eslint-disable-line no-unused-vars
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars



  const isPWAInstalled = async () => {
    if ("getInstalledRelatedApps" in window.navigator) {
      const relatedApps = await navigator.getInstalledRelatedApps();
      let installed = false;
      relatedApps.forEach((app) => {
        //if your PWA exists in the array it is installed
        console.log(app.platform, app.url);
        if (
          app.url ===
          "https://blyszczacezabki.pl/manifest.json"
        ) {
          installed = true;
        }
      });
      setIsAppInstalled(installed);
    }
  };

  useEffect(() => {
    isPWAInstalled();

  }, []);// eslint-disable-line react-hooks/exhaustive-deps




  return (
    <>
      {authContext.characterSelected ?
        <AnimatedBrushPrev imageBrush={authContext.characterSelected} />
        :

        <Grid divided style={{ height: "100%", margin: 0, padding: 0 }}>
          {auth ?
            <Grid.Row stretched style={{ height: "calc(100vh - 140px)", margin: 0, padding: 5, width: "100%", overflow: "auto", overflowX: "hidden" }}>

              {projectView(authContext.gView)}

            </Grid.Row>
            :

            <Grid.Row stretched style={{ height: "calc(100vh - 140px)", maxHeight: "calc(100vh - 140px)", overflow: "auto", margin: 0, paddingLeft: 0, paddingBottom: 0, paddingRight: 0, paddingTop: 10 }}>

              <Grid.Column width={width > 1000 ? 16 : 8} style={{ maxHeight: "100%" }} verticalAlign="middle">

                <Segment raised className="segmentStart">


                  {authContext.gView === "StartPage" ?<> <Label color={auth ? 'orange' : "blue"} ribbon style={{ marginBottom: 6 }}> ZOBACZ APLIKACJĘ</Label>
                  <div style={{width:30,float:"right"}}><Image src={swipe} /></div></>
                    : <Button size="mini" color="orange" fluid style={{ marginBottom: 5 }} onClick={() => projectView(authContext.changeView("StartPage"))}>STRONA GŁOWNA</Button>}

                  <SliderImagesFree />
                </Segment>
                {!isAppInstalled ? (
                  <Container textAlign="center" style={{ fontSize: 10 }}>
                    Kliknij i zobacz czy Twoja przeglądarka obsługuje:
                    <Button size="mini" fluid onClick={promptToInstall}>Zainstaluj witrynę jako aplikację</Button>
                  </Container>

                ) : ""}
              </Grid.Column>
              <GridColumn width={width > 1000 ? 16 : 8} verticalAlign="middle" style={{ maxHeight: "100%", paddingTop: 5, paddingBottom: 5 }}>


                {projectView(authContext.gView)}


              </GridColumn>


            </Grid.Row>
          }

          <Grid.Row style={{ width: "100%", height: 120, margin: 0, padding: 0 }}>
            <Grid.Column width={16}>

              <SliderImages />

            </Grid.Column>
          </Grid.Row>
        </Grid>


      }
    </>
  );
}

export default SelectACharacter;
