import { useState, useEffect, useContext } from "react";
import AnimatedBrush from "./AnimatedBrush";

import { Button, Icon } from "semantic-ui-react";
import beep2 from "../assets/mp3/beep.mp3";
import AuthContext from '../context/authContext';

function AnimatedBrushPrev({ imageBrush }) {
  const [start, setStart] = useState(false);
  const [isFinish, setisFinish] = useState(false);
  const [counter, setCounter] = useState(3);
  const authContext = useContext(AuthContext);

  let timer


  function startBeep() {
    try {
      new Audio(beep2).play();
    } catch (e) {
      console.log(e)
    }
  }


  useEffect(() => {
    if (start) {

      startBeep()

    }
    if (counter === "GO") {
      clearInterval(timer);
      setTimeout(() => {
        setisFinish(true)
        setStart(false)
      }, 1000);


    } else {

    }
  }, [counter]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (start) {
      startBeep()
      // eslint-disable-next-line
      timer = setInterval(() => {

        setCounter(prevCount => (prevCount - 1) > 0 ? prevCount - 1 : "GO"); 

      }, 1000);

    }

    return () => {
      clearInterval(timer);
    };
  }, [start]);// eslint-disable-line react-hooks/exhaustive-deps


  return (
    <>
    <Icon className="cursorpointer" color="blue"  inverted name="x" bordered style={{position:"absolute",left:5,top:5,zIndex:1000}} onClick={()=>authContext.changecharacterSelected(null)}/>
 
      {isFinish ? <AnimatedBrush imageBrush={imageBrush} timerAnimation={1.7}  /> :
      <><div className="prevImgCharacters"><img src={imageBrush} alt="preimg"/></div>
        <div className="preStartCenter">
          {start ? counter : <Button color="blue" size="massive" onClick={() => setStart(true)}>ROZPOCZNIJ MYCIE</Button>}


        </div>
      </>
      }
    </>
  );
}

export default AnimatedBrushPrev;
