import { useState, useEffect, useContext } from "react";

import { Card, CardGroup, Divider, Header, Icon, Image, } from "semantic-ui-react";
import AuthContext from './context/authContext';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



import MyImageLazy from "./MyImageLazy";
import swipe from '../src/assets/img/swipe.gif'
import dodajobr from '../src/assets/img/dodajobr.jpg'

import useAuth from "./hooks/useAuth";
import { ConnectWS } from "./ConnectWS/ConnectWS";
import { useToasts } from "react-toast-notifications";
import { loadingShow } from "./myfunctions/myfunctions";


function SliderImages() {
  const authContext = useContext(AuthContext);
  const [auth, setAuth] = useAuth();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [arrPic, setarrPic] = useState([]);



  useEffect(() => {
    getAllImages()

    return () => {

    };
  }, [authContext.refAllView]);// eslint-disable-line react-hooks/exhaustive-deps




  function selectedCharacters(row) {
    if (row.block && auth) {
      addToast('Odblokuj dostęp', { appearance: 'info' });
    } else if (row.block) {
      addToast('Musisz być zalogowany', { appearance: 'info' });
    } else if (auth) {
      authContext.changecharacterSelected(row.url)
    } else {
      addToast('Hmm coś poszło nie tak', { appearance: 'info' });

    }


  }

  var settings_3 = {
    dots: false,
    infinite: false,
    swipeToSlide: true,
    slidesToShow: 11,
    responsive: [{
      breakpoint: 1224,
      settings: {
        slidesToShow: 7,
        swipeToSlide: true,

      }
    }, {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        swipeToSlide: true,

      }
    },
    {
      breakpoint: 710,
      settings: {
        slidesToShow: 4,
        swipeToSlide: true,

      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 3,
        swipeToSlide: true,

      }
    }],

  };


  function getAllImages() {
    setLoading(true)
    ConnectWS("/getImagesAll", authContext.token, {}, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {

        let dictemat = []

     
        if (!auth) {
          dictemat.push({ name: "Dodaj obrazek", category: "All", url: dodajobr, block: 1 })
        }

        response.data.forEach((data, i) => {
          data.images.forEach((rowimg, i) => {
            if (!auth && rowimg.block === true) {
              dictemat.push({ name: rowimg.img_name, category: data.category_name, url: rowimg.img_url, block: rowimg.block })
            } else if (auth) {
              dictemat.push({ name: rowimg.img_name, category: data.category_name, url: rowimg.img_url, block: rowimg.block })
            }

          })
        })

        setarrPic(dictemat.sort((a, b) => {
          if (a.block < b.block) {
            return -1;
          } else {
            return 1;
          }
        }))

      } else {
        addToast(response, { appearance: 'error' });
      }
      setLoading(false)

    });
  }



  return (

    <>
      <Divider horizontal style={{ margin: 0, padding: 0 }} >

        <Header style={{ width: 40 }}>
          <Image src={swipe} />

        </Header>
      </Divider>
      {auth ? <div style={{ position: "absolute", right: 15, top: 4, color: "grey", fontSize: 10 }}><b>Kontakt:</b> info@blyszczacezabki.pl</div> : ""}
      {loading ? loadingShow(1) :
        <Slider style={{ marginLeft: 20, marginRgiht: 20, width: "95%", marginTop: 10, paddingLeft: 10, paddingRight: 10 }} {...settings_3} >
          {arrPic.map((row, i) => <CardGroup centered key={i}><Card style={{ width: 120, height: 60, overflow: "hidden", position: "relative" }} as="a" key={i} onClick={() => selectedCharacters(row)}>
            {row.block ? <div className="ribbon2"><span><Icon name="lock" /> LOCK</span></div> : ""}


            <MyImageLazy className={row.block ? "imgCharacters imgCharactersOpacity" : "imgCharacters"} src={row.url} alt="zdjecie2" />

          </Card></CardGroup>)}

        </Slider>
      }
    </>
  );
}

export default SliderImages;
