import React, { useEffect } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { Grid, Header, Icon, Container, Segment } from 'semantic-ui-react'

function Statue() {

  useEffect(() => {

    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return (

    <Grid stackable >
      <Grid.Row >


        <Grid.Column width={16} >
          <Container textAlign='center' style={{marginTop:10}}>

            <Header as='h1' textAlign='center'>
              <Icon name='mail' color="blue" />
              <Header.Content>Regulamin</Header.Content>
            </Header>

            <Segment>
              <article className="Btext">
                Poniższe warunki regulują korzystanie ze strony blyszczacezabki.pl oraz wszystkich treści, usług i produktów dostępnych na stronie lub za jej pośrednictwem. Administrator serwisu blyszczacezabki.pl zastrzega sobie prawo do ograniczenia lub zakończenia dostępu użytkownika do tej witryny internetowej lub zakończenia lub zawieszenia rejestracji użytkownika z powodu nieprzestrzegania warunków i zasad opublikowanych w tym regulaminie lub z dowolnego powodu w dowolnym momencie.
             
                  <br />
                <Header as="h3">1. Postanowienia Ogólne</Header>
                1.1. Niniejszy regulamin określa zasady korzystania z aplikacji internetowej blyszczacezabki.pl (dalej: "Aplikacja").
                <br />  1.2. Aplikacja została stworzona, aby ułatwić rodzicom proces mycia zębów u ich dzieci. Dzieci skupiają uwagę podczas szczotkowania, śledząc odsłaniany obrazek.
                <br />  1.3. Administrator serwisu blyszczacezabki.pl Mateusz Bodziony, może w dowolnym momencie zmieniać, modyfikować, aktualizować, dodawać lub usuwać fragmenty niniejszych Warunków. Prosimy o okresowe sprawdzanie niniejszych Warunków pod kątem zmian. Dalsze korzystanie z tej Witryny po opublikowaniu jakichkolwiek zmian będzie oznaczać akceptację tych zmian.
                <br />  1.4. Dążąc do ciągłego ulepszania tej Witryny i jej użyteczności, możemy dodawać dodatkowe usługi lub wprowadzać zmiany w istniejących usługach. W przypadku wprowadzenia takich zmian, niniejsze Warunki mają zastosowanie do nowych usług i wszelkich zmian w istniejących usługach.
                <Header as="h3">2. Warunki Użytkowania</Header>
                2.1. Aby korzystać z niektórych części tej Witryny, zostaniesz poproszony o podanie informacji o sobie, takich jak adres e-mail , hasło. Ponosisz całkowitą odpowiedzialność za zachowanie poufności informacji o swoim koncie, w tym hasła, oraz za wszelką aktywność wykonywaną na Twoim koncie. Użytkownik zgadza się niezwłocznie powiadomić administratora serwisu o każdym nieautoryzowanym użyciu konta lub hasła lub jakimkolwiek innym naruszeniu bezpieczeństwa.
                <br />2.2. W trosce o ochronę prywatności naszych użytkowników, niniejszym zapewniamy, że adresy email podane przez użytkowników podczas rejestracji lub w innych formularzach na naszej stronie internetowej nie będą publicznie prezentowane, udostępniane ani eksponowane w żadnej części aplikacji. Gwarantujemy zachowanie pełnej poufności tych danych, zgodnie z obowiązującymi przepisami o ochronie danych osobowych oraz naszą polityką prywatności. Adresy email będą wykorzystywane wyłącznie do celów komunikacji bezpośredniej z użytkownikami w zakresie niezbędnym do świadczenia naszych usług.
                <br />2.3. Nie możesz używać niczyjego konta w dowolnym momencie bez wyraźnej zgody posiadacza tego konta. Ważne! Konto użytkownika i hasła są przeznaczone wyłącznie do użytku indywidualnego i nie powinny być udostępniane. Konta grupowe i ogólne są zabronione. Administrator nie może i nie będzie ponosić odpowiedzialności za jakiekolwiek straty lub szkody wynikające z nieprzestrzegania tych zobowiązań..

              
                <Header as="h3">3. Rodzaje Płatności</Header>
                3.1. Utworzenie konta użytkownika w aplikacji jest darmowe.
                <br />3.2. Dostęp do przykładowych obrazków jest darmowy.
                <br />3.3. Aplikacja oferuje możliwość odblokowanie całej funkcjonalności w tym dostęp do większej ilości obrazków oraz możliwości dodania dwóch własnych linków do obrazków w kwocie {process.env.REACT_APP_SUBSCRIPTION_PRICE}PLN na okres 1 miesiąca.
                <br />3.4. Płatności nie odnawiaja się automatycznie.

                <Header as="h3">4. Odblokowanie funkcjonalności aplikacji</Header>
                4.1. Odblokowanie funkcjonalności aplikacji reazlizowane jest poprzez system płatności dostępny w Aplikacji, płatności realizowane są za pomocą systemu HotPay.pl.
                <br />4.2. System nie wymaga podpinania kart kredytowych, debetowych, a wszelkie płatności są realizowane na zasadzie jednorazowych.
               

                <Header as="h3">5. Ograniczenie Odpowiedzialności</Header>
                5.1. Dostawca Aplikacji nie ponosi odpowiedzialności za straty wynikające z nieprawidłowego użytkowania Aplikacji.
                <br />5.2. Dostawca Aplikacji gwarantuje ciągłej dostępności Aplikacji na poziomie 99.9% miesiaca.

                <Header as="h3">6. Reklamacje i zwroty</Header>
                6.1. Użytkownik ma prawo zgłaszać reklamacje dotyczące usług świadczonych przez Aplikację.
                <br />6.2. Reklamacja powinna zostać złożona w formie elektronicznej na adres kontaktowy dostawcy Aplikacji tj. info@blyszczacezabki.pl i zawierać:
                <br /> a. Adres e-mail Użytkownika.
                <br /> b. Szczegółowy opis problemu i okoliczności jego wystąpienia.
              
                <br />6.3. Dostawca Aplikacji zobowiązuje się rozpatrzyć reklamację w terminie 7 dni od daty jej otrzymania. W przypadku, gdyby rozpatrzenie reklamacji w tym terminie nie było możliwe, dostawca poinformuje Użytkownika o przyczynach opóźnienia i przewidywanym terminie rozstrzygnięcia sprawy.
                <br />6.4. Odpowiedź na reklamację zostanie przesłana Użytkownikowi na wskazany przez niego adres e-mail.
                <br />6.5. W przypadku, gdy reklamacja zostanie uznana za uzasadnioną, dostawca Aplikacji podejmie niezbędne działania w celu usunięcia zaistniałych problemów.
                <br />6.6. Zwroty będą akceptowane jedynie w przypadku, gdy zgłoszenie zwrotu zostanie złożone w ciągu 2 godzin od wykonania płatności.
                <br />6.7. Zgłoszenia zwrotów można dokonywać poprzez przesłanie wiadomości e-mail na adres info@blyszczacezabki.pl.
                <br />6.8. Zwrot środków zostanie zrealizowany na oryginalne konto lub metodę płatności użyte przy dokonywaniu transakcji.
                <br />6.9. Zwroty będą rozpatrywane w ciągu 3 dni roboczych od daty zgłoszenia. Po rozpatrzeniu zgłoszenia klient zostanie poinformowany o statusie zwrotu.
                <br />6.10. Administrator zastrzega sobie prawo odmówienia zwrotu w przypadku podejrzenia nadużycia lub naruszenia warunków regulaminu.
            

                <Header as="h3">7. Zakończenie</Header>
                7.1. Jeżeli stosunek sporu zawiera element międzynarodowy (zagraniczny), strony zgadzają się, że stosunek ten podlega prawu polskiemu. Wybór prawa w zdaniu poprzednim nie pozbawia konsumenta ochrony, jaką dają mu przepisy prawa, od których nie można wyłączyć w drodze umowy, a które w innym przypadku miałyby zastosowanie na podstawie art. 6 ust. 1 Rozporządzenia (WE) nr 593/2008 Parlamentu Europejskiego i Rady z dnia 17 czerwca 2008 r. w sprawie prawa właściwego dla zobowiązań umownych (Rzym I).
                <br /> 7.2. Jeżeli którekolwiek z postanowień Regulaminu jest lub stanie się nieważne lub nieskuteczne, nieważne postanowienie zostanie zastąpione postanowieniem, którego znaczenie jest jak najbardziej zbliżone do postanowienia nieważnego. Nieważność lub bezskuteczność jednego postanowienia nie wpływa na ważność pozostałych postanowień.
                <br />
                <br />01 Luty 2024r.
                <br/><br/>
                Regulamin jest integralną częścią <Link to="polityka-prywatnosci" target="_blank" rel="noopener noreferrer" > Polityki Prywatności</Link>.
              </article>
            </Segment>
          </Container>
        </Grid.Column>


      </Grid.Row>
    </Grid>

  )
}

export default Statue;