
import dateFormat from 'dateformat';
import NotFound from '../404';
import Login from '../Login/Login';
import Register from '../Register/Register';
import StartPage from '../Ui/StartPage';
import Dashboard from '../Ui/Dashboard';
import { Container, Header, Icon } from 'semantic-ui-react';

export function projectView(View) {
    window.localStorage.setItem('gView', View);
    switch (View) {

        case "Login": return <Login  />;
        case "Register": return <Register />;
        case "StartPage": return <StartPage />;
        case "Dashboard": return <Dashboard />;
        
        default: return <NotFound />
    }
}

export function changeFormatDate(mydate){
     
    mydate ? mydate=dateFormat(mydate,"yyyy-mm-dd") : mydate=""
    return mydate;
    
}
export function changeFormatDateTime(mydate){
     
    mydate ? mydate=dateFormat(mydate,"yyyy-mm-dd'T'HH:MM:ss") : mydate=""
    return mydate;
    
}

export function changeFormatDateTimeDB(mydate){
     
    mydate ? mydate=dateFormat(mydate,"yyyy-mm-dd HH:MM:ss") : mydate=""
    return mydate;
    
}

export function getDateTimeE(co,mydate){
 if(co==="TIME"){
   return dateFormat(mydate,"HH:MM:ss")
 }else{
   return dateFormat(mydate,"yyyy-mm-dd")
 }
}

export function checkTimeVal(DateFrom, DateTo) {
 
    console.log(changeFormatDateTimeDB(Date.parse(DateTo)),changeFormatDateTimeDB(Date.parse(DateFrom)),Date.parse(DateTo)-Date.parse(DateFrom))
    if ((Date.parse(DateTo) - Date.parse(DateFrom)) <= 0) {
        return true;
    } else {
        return false;
    }

}

export function loadingShow(ver) {
    if(ver===1){
        return <Container textAlign='center' >
        <Header as='h6' icon>
            <Icon loading name='spinner'  />
            Trwa ładowanie
        </Header>
    </Container>
    }else{
        return <Container textAlign='center' >
        <Header as='h4' icon>
            <Icon loading name='spinner' />
            Trwa ładowanie
            <Header.Subheader>
                proszę czekać...
            </Header.Subheader>
        </Header>
    </Container>
    }
    
}



export function SCezarencrypt(text, shift = 7) {
    var result = "";


    //loop through each caharacter in the text
    for (var i = 0; i < text.length; i++) {

        //get the character code of each letter
        var c = text.charCodeAt(i);

        // handle uppercase letters
        if (c >= 65 && c <= 90) {
            result += String.fromCharCode((c - 65 + shift) % 26 + 65);

            // handle lowercase letters
        } else if (c >= 97 && c <= 122) {
            result += String.fromCharCode((c - 97 + shift) % 26 + 97);

            // its not a letter, let it through
        } else {
            result += text.charAt(i);
        }
    }

    return result;
}

export function SCezardecrypt(text, shift = 7) {
    var result = "";
    shift = (26 - shift) % 26;
    result = SCezarencrypt(text, shift);
    return result;
}



export function changePageName(newName = "Błyszczące Ząbki - Aplikacja do wspomagania mycia zębów") {
    document.title = newName + " - blyszczacezabki.pl";
}

export function getNumerRound(){
    return Math.floor(Math.random() * (30 - 10 + 1) + 10)
}