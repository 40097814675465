import { useEffect, useContext, useState } from "react";


import { Button,  Form, Grid, Icon, IconGroup, Image,  Message, MessageContent, MessageHeader } from "semantic-ui-react";

import AuthContext from "../context/authContext";
import { ConnectWS } from "../ConnectWS/ConnectWS";
import { useToasts } from "react-toast-notifications";


function MyPictureView(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [isEdit, setisEdit] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [form, setform] = useState({ profile_id: 0, url_image1: null, url_image2: null, nr: 0 });
  const [iserror, setiserror] = useState(false);

  
  useEffect(() => {

    return () => {

    };
  }, []);


  const submit = e => {
    setLoading(true)
    ConnectWS("/setProfileUrl", authContext.token, { profile_id: form.profile_id, url_image1: form.url_image1, url_image2: form.url_image2 }, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'info' });
        editImg(false, 0)
        authContext.changeProfile({ ...authContext.profile, url_image1: form.url_image1, url_image2: form.url_image2 })
        props.getProfile()

      } else {
        addToast(response, { appearance: 'error' });
      }
      setLoading(false)

    });
  }

  function showImage(urlimage){
    if(authContext.userInfo.subscriptionTo===null){
      addToast("Odblokuj dostęp", { appearance: 'info' });
    
    
    }else{
      authContext.changecharacterSelected(urlimage)
    }
  }

  function editImg(co, nr) {
    if(authContext.userInfo.subscriptionTo===null){
      addToast("Odblokuj dostęp", { appearance: 'info' });
    
    
    }else{
      setform({ profile_id: authContext.profile.profile_id, url_image1: authContext.profile.url_image1, url_image2: authContext.profile.url_image2, nr: nr })
      setisEdit(co)
    }
    
  }

  function updateForm(form, url) {
    if (form.nr === 1) {
      setform({ ...form, url_image1: url === "" ? null : url })
    } else {
      setform({ ...form, url_image2: url === "" ? null : url })
    }

  }

  return (
    isEdit ?
      <Grid  style={{ margin: 0 }} >
        <Grid.Row stretched style={{ margin: 0, padding: 0}}>

          <Grid.Column textAlign="center" width={9} verticalAlign="middle">
          <Message size="mini" info icon>
              <Icon name='image' />
              <MessageContent>
                <MessageHeader>Informacja</MessageHeader>
                Znajdz obrazek w internecie i wklej poniżej url do niego
              </MessageContent>

            </Message>
            <Form size='mini' style={{ marginTop: 5 }} onSubmit={submit} autoComplete="off">
              <Form.Input fluid type="text" icon='image'
                value={form.nr === 1 ? form.url_image1 : form.url_image2}
                onChange={(event, data) => updateForm(form, data.value)}
                label="Adres url obrazka"
                iconPosition='left'
                placeholder='Adres url obrazka' />
              <Button size="mini" color="blue" loading={isloading} disabled={iserror} fluid>ZAPISZ</Button>
            </Form>
            <Button size="mini" style={{marginTop:10}} onClick={() => editImg(false, 0)} fluid>POWRÓT</Button>
          </Grid.Column>
          <Grid.Column textAlign="center" width={7} verticalAlign="middle" >
            {(form.url_image1 === null && form.nr === 1) || (form.url_image2 === null && form.nr === 2) ?
              <IconGroup size='huge' >
                <Icon name='image' color="grey" />

              </IconGroup>
              :
              <Image className="imageTwoCharProfil cursorpointer" src={form.nr === 1 ? form.url_image1 : form.url_image2} wrapped ui={false} onClick={() => null}
                onLoad={() => setiserror(false)}
                onError={({ currentTarget }) => {
                  setiserror(true)
                }}>

              </Image>
            }
            {iserror ? <Message size="mini" error icon>
              <Icon name='circle info' />
              <MessageContent>
                <MessageHeader>Nie można pobrać obrazka</MessageHeader>
                1. Sprawdź czy link jest poprawny<br />
                2. Możliwe że serwer z którego probujesz załadować obraz to blokuje
              </MessageContent>

            </Message> : ""}

          </Grid.Column>
        </Grid.Row>

      </Grid>


      :
      <Grid divided style={{ margin: 0, paddingLeft: 100, paddingRight: 100 }} >

        <Grid.Row columns={2} style={{ margin: 0, padding: 0 }}>
          <Grid.Column style={{}} >

            {authContext.profile.url_image1 ?
              <Image className="imageTwoCharProfil cursorpointer" src={authContext.profile.url_image1} onClick={() => showImage(authContext.profile.url_image1)} wrapped ui={false} />
              : <IconGroup size='huge' style={{ marginBottom: 5 }}>
                <Icon name='image' color="grey" />
                <Icon corner color="orange" name='add' />
              </IconGroup>}
            <Button size="mini" color="blue" onClick={() => editImg(true, 1)} fluid>{authContext.profile.url_image1 ? "ZMIEN OBRAZEK" : "DODAJ OBRAZEK"}</Button>
          </Grid.Column>
          <Grid.Column >
            {authContext.profile.url_image2 ?
              <Image className="imageTwoCharProfil cursorpointer" src={authContext.profile.url_image2} wrapped ui={false} onClick={() => showImage(authContext.profile.url_image2)} />
              : <IconGroup size='huge' style={{ marginBottom: 5 }}>
                <Icon name='image' color="grey" />
                <Icon corner color="orange" name='add' />
              </IconGroup>}
            <Button size="mini" color="blue" onClick={() => editImg(true, 2)} fluid>{authContext.profile.url_image2 ? "ZMIEN OBRAZEK" : "DODAJ OBRAZEK"}</Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>







  );
}

export default MyPictureView;
