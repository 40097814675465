import React from 'react';

const AuthContext = React.createContext({
    isAuthenticated: false,
    characterSelected: null,
    userInfo:"",
    token: null,
    profile: null,
    gView:"StartPage",
    refAllView: 0,
    login:() => {},
    logout:() => {},
    changeUserInfo:(val) => {},
    changeProfile:(val) => {},
    changecharacterSelected:(val) => {},
    changeToken:(val) => {},
    changeView:(val) => {},
    changeRefAllView:(val) => {},
});

export default AuthContext;