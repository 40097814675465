import React, { useState, useEffect, useContext } from 'react';
import { Form,  Segment, Checkbox, Button, Container, Icon } from 'semantic-ui-react'

import useAuth from '../hooks/useAuth';
import { ConnectWS } from '../ConnectWS/ConnectWS';
import AuthContext from '../context/authContext';
import { useCookies } from 'react-cookie';
import { useToasts } from 'react-toast-notifications';
import { SCezardecrypt, SCezarencrypt } from '../myfunctions/myfunctions';


const cookieVer = 1;

function Login(props) {
    const { addToast } = useToasts();
    const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
    const [isloading, setLoading] = useState(false);
    const [error, setError] = useState("");// eslint-disable-line no-unused-vars
    const authContext = useContext(AuthContext);

    const [CheckboxS, setCheckboxS] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['UserData', 'MessageInfo' + cookieVer]);
    const [FormE, setFormE] = useState({ email: "", password: "" });

    const submit = e => {
        setError("");
        let email = ""
        let password = ""

        email = FormE.email;
        password = FormE.password;


        setLoading(true);

        ConnectWS("/login", null, { email: email, password: password }, function (response,logout) {

            if (logout === 1) {
                addToast(response, { appearance: 'error' });
                setLoading(false);
            }else if (response && response.status && Number(response.status) === 200) {


                if (CheckboxS) {
                    let expiresdate = new Date();
                    let today = new Date();
                    expiresdate.setDate(today.getDate() + 30);
                    setCookie('UserData', { login: email, password: SCezarencrypt(password) }, { path: '/', expires: expiresdate, sameSite: 'lax' });
                }
                let userInfo = {...response.data,s:password,email:email}
                authContext.changeUserInfo(userInfo)
                authContext.changeToken(response.data.token);
                setAuth(true, response.data.token, JSON.stringify(userInfo));
                setLoading(false);
                

            } else {
                addToast(response, { appearance: 'error' });
                setLoading(false);
                setAuth(false);
                setError((response));
            }
            


        });




    }




    useEffect(() => {
        if (cookies.UserData) {
            setFormE({ ...FormE, email: cookies.UserData.login, password: SCezardecrypt(cookies.UserData.password) })
            setCheckboxS(true)
        }

        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {


        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    const onChangeCheckbox = (evt, data) => {

        if (data.checked) {

        }
        else {
            removeCookie('UserData')
        }
        setCheckboxS(data.checked)

    }


    const remindPass = e => {
        e.preventDefault();
        setLoading(true)
        console.log(FormE)
        setError(null)
        if (typeof FormE.email === 'undefined' || FormE.email.length < 3 || FormE.email.indexOf("@") < 1) {
            //  setloginStatus({ value: "Wpisz prawidłowy adres e-mail", error: true, success: false })
            addToast('Wpisz prawidłowy adres e-mail', { appearance: 'warning' });
            setLoading(false)
            return
        }
        let email = FormE.email
        ConnectWS("resetPassword", null, { email: email }, function (response, logout) {

            if (logout === 1) {
               
            } else if (response && response.status && Number(response.status) === 200) {


            } else {


                addToast(response, { appearance: 'error' });
                console.log(response);
            }
            setLoading(false)


        });
    }


    //
    return (
        <>

                    <Segment  color="blue" className='loginSemgent' >

                        <Form size='mini' style={{ marginTop: 5 }} onSubmit={submit} autoComplete="off">
                            <Form.Input fluid type="email" icon='user' name='username' value={FormE.email} onChange={(event, data) => setFormE({ ...FormE, email: data.value })} required iconPosition='left' placeholder='Email' />
                            <Form.Input
                                fluid
                                icon='lock'
                                iconPosition='left'
                                placeholder='Hasło'
                                type='password'

                                name='Hasło'
                                value={FormE.password}
                                onChange={(event, data) => setFormE({ ...FormE, password: data.value })}
                                //value={cookies.UserData ? cookies.UserData.password : ""}
                                required
                            />



                            <Checkbox size="mini" label='Pamiętaj moje dane logowania' checked={CheckboxS ? true : false} style={{ 'paddingBottom': '10px' }} onClick={(evt, data) => onChangeCheckbox(evt, data)} />

                            <Button size='mini' color="blue" fluid disabled={isloading ? true : false} loading={isloading} animated='fade'>
                                <Button.Content visible>ZALOGUJ SIĘ</Button.Content>
                                <Button.Content hidden>Jeszcze jeden klik i ...</Button.Content>
                            </Button>

                            {error ? (
                                <>
                                    <div className="ui negative message"><div className="header">{error}</div></div>
                                </>
                            ) : ''}

                        </Form>
                        <a href="!#" className="cursorpointer" onClick={(e) => remindPass(e)}><Icon name="key" />Przypomnienie hasła</a>

                        <Container textAlign='center' className='cursorpointer' onClick={() => authContext.changeView("Register")} style={{ marginTop: 5, textDecoration: "underline" }}>
                            Nie mam konta
                        </Container>

                    </Segment>


                </>


    );
}



export default Login;