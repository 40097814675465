import { useState, useEffect, useContext } from "react";

import { Card, CardGroup } from "semantic-ui-react";
import AuthContext from './context/authContext';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ConnectWS } from "./ConnectWS/ConnectWS";
import { useToasts } from "react-toast-notifications";
import { loadingShow } from "./myfunctions/myfunctions";
import MyImageLazy from "./MyImageLazy";



function SliderImagesFree() {
  const authContext = useContext(AuthContext);
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [arrPic, setarrPic] = useState([]);


  useEffect(() => {
    getAllImages()
    return () => {

    };
  }, []);// eslint-disable-line react-hooks/exhaustive-deps




  function selectedCharacters(row) {
    authContext.changecharacterSelected(row.url)
  }

  var s = {
    dots: false,
    swipeToSlide: true,
    infinite: true
  }

  var settings_3 = {
    dots: false,
    slidesToShow: 11,
    ...s,
    responsive: [{
      breakpoint: 1224,
      settings: {
        slidesToShow: 7,
        ...s,
      }
    }, {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        ...s,
      }
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        ...s,

      }
    },
    {
      breakpoint: 500,
      settings: {
        //   slidesToShow: 2,
        ...s,

      }
    }]
  }


  function getAllImages() {
    setLoading(true)
    ConnectWS("/getImagesFree", authContext.token, {}, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {

        let dictemat = []

        response.data.forEach((data, i) => {
          dictemat.push({ name: data.img_name, category: "Free", url: data.img_url })
        })

        dictemat=dictemat.concat(dictemat)
        dictemat=dictemat.concat(dictemat)
        dictemat=dictemat.concat(dictemat)
        dictemat=dictemat.concat(dictemat)


        setarrPic(dictemat)

      } else {
        addToast(response, { appearance: 'error' });
      }
      setLoading(false)

    });
  }



  return (

    <>

      {loading ? loadingShow(1) :
        <Slider style={{ marginTop: 10 }} {...settings_3} >
          {arrPic.map((row, i) => <CardGroup centered key={i}><Card style={{ width: 130, height: 70, overflow: "hidden", position: "relative" }} as="a" key={i} onClick={() => selectedCharacters(row)}>
            <MyImageLazy className={"imgCharacters"} src={row.url} alt="zdjecie2" />
          </Card></CardGroup>)}
        </Slider>
      }
   
    </>
  );
}

export default SliderImagesFree;
