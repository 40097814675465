import { useEffect, useContext, useState } from "react";


import { Button, Container, Form } from "semantic-ui-react";

import AuthContext from "../context/authContext";
import { ConnectWS } from "../ConnectWS/ConnectWS";
import { useToasts } from "react-toast-notifications";

function AddProfile(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [form, setForm] = useState({ profilname: "", });
  const [isloading, setLoading] = useState(false);

  useEffect(() => {



    return () => {

    };
  }, []);


  const submit = e => {
   
    if(form.profilname.length>20){
      addToast("Max długość nazwy to 20 znaków", { appearance: 'warning' });
      return
    }
    setLoading(true);

    ConnectWS("/addProfile", authContext.token, { name: form.profilname }, function (response, logout) {

      if (logout === 1) {
      

      } else if (response && response.status && Number(response.status) === 200) {

        addToast(response.data.message, { appearance: 'info' });
        props.setaddProfile(false)
        props.getProfile()

      } else {
        addToast(response, { appearance: 'error' });
      }
      setLoading(false);


    });

  }

  return (
    <Container textAlign="center">


      <Form size='mini' style={{ marginTop: 5 }} onSubmit={submit} autoComplete="off">
        <Form.Input fluid  icon='user' type="text" value={form.profilname}
        onChange={(event, data) => setForm({ ...form, profilname: data.value })} required iconPosition='left' placeholder='Nazwa profilu' />


        <Button size='mini' color="blue" fluid disabled={isloading ? true : false} loading={isloading} animated='fade'>
          <Button.Content visible>DODAJ PROFIL</Button.Content>
          <Button.Content hidden>Jeszcze jeden klik i ...</Button.Content>
        </Button>


      </Form>

    </Container>
  );
}

export default AddProfile;
