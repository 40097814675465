import { useEffect, useContext, useState } from "react";


import { Button,  Container, Icon, Image, Input, Message, Modal, ModalActions, ModalContent,  ModalHeader } from "semantic-ui-react";

import AuthContext from "../context/authContext";
import { ConnectWS } from "../ConnectWS/ConnectWS";
import { useToasts } from "react-toast-notifications";
import sprto from '../../src/assets/img/sprto.png'
import { confirmAlert } from "react-confirm-alert";
import { getNumerRound } from "../myfunctions/myfunctions";

function AddSubstricption(props) {
  const [open, setOpen] = useState(false)
  const [form, setForm] = useState(null);
  const [message, setMessage] = useState(null);
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);


  useEffect(() => {

    if (!open) {
      setMessage(null)
    }


    return () => {

    };
  }, [open]);


  function generateHotPayData() {
    ConnectWS("/payment/generateHotPayDataSubscription", authContext.token, {}, function (response, logout) {

      if (logout === 1) {
        addToast("Błędne dane logowania", { appearance: 'error' });
        setMessage(response.data)
        setForm(null)
      } else if (response && response.status && Number(response.status) === 200) {
        setOpen(false)
        setForm(response.data)
        document.getElementById("orderHPBZ").submit();
        setMessage(null)
      } else {
        addToast(response, { appearance: 'error' });
        console.log(response);
        setForm(null)
        setMessage(response)
      }


    });
  }

  function addMonthToSub(){
    const num1 = getNumerRound();
    const num2 = getNumerRound();
    let sum = 0

    confirmAlert({
      customUI: ({ onClose }) => {
      
        return (
          <div className='react-confirm-alert'>
            <div className="react-confirm-alert-body">
            <h1>Proszę potwierdzić</h1>
            <p>Czy chcesz przedłużyć dostęp o kolejne 31 dni?</p>
     
           
            <Input
            label={`Ile wynosi ${num1} + ${num2} = `}
              type="number"
              autoFocus
              onChange={(e) => sum = (e.target.value)}
            />
           
            <div className="react-confirm-alert-button-group">
            <Button
       
              onClick={() => {
                onClose();
              }}
            >
              Zamknij
            </Button>
            <Button
              onClick={() => {
                if (parseInt(sum) === num1 + num2) {
                  onClose();
                  generateHotPayData()
                } else {
                  addToast("Nieprawidłowa odpowiedź. Spróbuj ponownie.", { appearance: 'error' });
                }
              }}
            >
              Potwierdź
            </Button>
            </div>
            </div>
          </div>
        );
      },
    });
  }



  return (
    <>

      {authContext.userInfo && authContext.userInfo.subscriptionTo!==null  ?
      props.imgOff ? "":
        <>
          Dostęp do: {authContext.userInfo.subscriptionTo}
          <Button color="blue" onClick={() => addMonthToSub()}>Przedłóż dostęp o 31 dni - {process.env.REACT_APP_SUBSCRIPTION_PRICE} PLN</Button>
        </>
        : props.imgOff ? <Button color="green" onClick={() => setOpen(true)}>Uzyskaj dostęp do pełniej funkcjonalności</Button> : <><Image src={sprto} size="small" centered /> <Button color="green" onClick={() => setOpen(true)}>Uzyskaj dostęp do pełniej funkcjonalności</Button></>}

      <Modal
        onClose={() => setOpen(false)}
        open={open}
        dimmer={"blurring"}


      >
        <ModalHeader>Uzyskaj dostęp do pełnej funkcjonalności</ModalHeader>
        <ModalContent scrolling>
          <Container textAlign="center" >

            {message ? <Message negative>
              <Message.Header>ERROR</Message.Header>
              <p>{message}</p>
            </Message> : ""}

            Mycie zębów znajduje się na liście czynności, które są równie trudne do polubienia przez nasze dziecko,
            co jedzenie czerwonej papryki. Pomimo naszych starań, które obejmowały tłumaczenie znaczenia tej czynności,
            korzystanie z publikacji dostosowanych do wieku dziecka oraz wprowadzenie kalendarza z zadaniami do odhaczenia,
            nie udało nam się zainteresować naszego malucha regularnym szczotkowaniem zębów. Stworzyliśmy aplikację,
            która wznosi tę codzienną czynność na wyższy poziom, sprawiając ze te 2 minuty rano i wieczorem są wydarzeniem o które mały człowiek sam się dopomina.
            <br /><br />
            BŁYSZCZĄCE ZĄBKI jest dostosowana do wieku odbiorców, zastosowane dźwięki i grafiki są spokojne,
            wszystko po to aby kontakt z ekranem telefonu był bezpieczny i nie powodował trudnych wychowawczo sytuacji.

            <br /><br />
            Po wykupieniu dostępu na cały miesiac (31 dni) za <strong>{process.env.REACT_APP_SUBSCRIPTION_PRICE} PLN</strong>, Twoje dziecko będzie miało dostęp do specjalnie przygotowanych obrazków,
            które uczynią poranne i wieczorne mycie zębów nie tylko higienicznym, ale także radosnym doświadczeniem. 
            <br /><br />
            <strong>A to nie koniec! Dodatkowo, dla każdego profilu możesz dodać po dwie dowolne grafiki jako linki,
              które możesz swobodnie zmieniać w każdym momencie. Stworzysz w ten sposób spersonalizowaną mini galerię,
              dostosowaną do zainteresowań Twojego dziecka.</strong>
              <br/><br/>
             <i> Płatności w naszej aplikacji nie ulegają automatycznemu odnowieniu. Po upływie miesiąca od zakupu dostępu, aplikacja automatycznie przełączy się w tryb darmowy.
               W dowolnym momencie masz możliwość przywrócenia pełnej funkcjonalności aplikacji lub jej przedłużenia o kolejne 31 dni.</i>

            

          </Container>
        </ModalContent>
        <ModalActions >
          <Button color='black' floated="left" onClick={() => setOpen(false)}>
            <Icon name='x' /> ZAMNKIJ
          </Button>
          <Button color='green' onClick={() => generateHotPayData()}>
            <Icon name='checkmark' /> WYKUP DOSTĘP {process.env.REACT_APP_SUBSCRIPTION_PRICE} PLN
          </Button>
        </ModalActions>
      </Modal>
      {form ?
        <form style={{ display: "none" }}
          id="orderHPBZ"
          action="https://platnosc.hotpay.pl/"
          method="post">
          <input required name="SEKRET" value={form.SEKRET} type="hidden" />
          <input required name="HASH" value={form.HASH} type="hidden" />
          <input required name="KWOTA" value={form.KWOTA} type="hidden" />
          <input required name="NAZWA_USLUGI" value={form.NAZWA_USLUGI} type="hidden" />
          <input required name="ADRES_WWW" value={form.ADRES_WWW} type="hidden" />
          <input required name="ID_ZAMOWIENIA" value={form.ID_ZAMOWIENIA} type="hidden" />
          <input name="EMAIL" value={form.EMAIL} type="hidden" />
          <input name="DANE_OSOBOWE" value={form.DANE_OSOBOWE} type="hidden" />
          <button type="submit">Submit</button>
        </form>
        : ""}
    </>
  );
}

export default AddSubstricption;
