import { useEffect, useContext, useState } from "react";


import { Button,  Card, CardContent, CardGroup, CardHeader,  Grid, Header, Icon, Image, Input,  Segment } from "semantic-ui-react";
import logobz from "../../src/assets/img/logo.png";

import AuthContext from "../context/authContext";
import useAuth from "../hooks/useAuth";
import { ConnectWS } from "../ConnectWS/ConnectWS";
import { useToasts } from "react-toast-notifications";
import { confirmAlert } from 'react-confirm-alert';
import AddProfile from "./AddProfile";
import AddSubstricption from "./AddSubstricption";
import MyPictureView from "./MyPictureView";
import { getNumerRound } from "../myfunctions/myfunctions";


function Dashboard() {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
  const [profiles, setProfiles] = useState([]);


  const [addProfile, setaddProfile] = useState(false);

  useEffect(() => {

    getProfile()

    return () => {

    };
  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  function getProfile() {

    ConnectWS("/getProfiles", authContext.token, {}, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {

        let dictemat = []
        if (response.data.length < 5) {
          dictemat.push({ profile_id: 0, name: "DODAJ PROFIL", url: logobz, url_image1:null, url_image2:null })
        }

        response.data.forEach((data, i) => {
          dictemat.push({ profile_id: data.profile_id, name: data.name, url: logobz, url_image1:data.url_image1, url_image2:data.url_image2})
          //dictemat.push({ key: i, text: data.Login, value: data.Login })
        })
        setProfiles(dictemat)
        authContext.changeRefAllView(authContext.refAllView + 1)

      } else {
        addToast(response, { appearance: 'error' });
      }


    });
  }

  function clikcProfile(row) {
    if (row.profile_id === 0) {
      setaddProfile(true)
    } else {
      // wybieram profil
      authContext.changeProfile(row)
    }
  }

  function logOut() {
    const num1 = getNumerRound();
    const num2 = getNumerRound();
    let sum = 0

    confirmAlert({
      customUI: ({ onClose }) => {
      
        return (
          <div className='react-confirm-alert'>
            <div className="react-confirm-alert-body">
            <h1>Proszę potwierdzić</h1>
            <p>Czy chcesz się wylogować?</p>
     
           
            <Input
            label={`Ile wynosi ${num1} + ${num2} = `}
              type="number"
              autoFocus
              onChange={(e) => sum = (e.target.value)}
            />
           
            <div className="react-confirm-alert-button-group">
            <Button
              onClick={() => {
                onClose();
              }}
            >
              Zamknij
            </Button>
            <Button
          
              onClick={() => {
                if (parseInt(sum) === num1 + num2) {
                  onClose();
                  logOutAcc()
                } else {
                  addToast("Nieprawidłowa odpowiedź. Spróbuj ponownie.", { appearance: 'error' });
                }
              }}
            >
              Potwierdź
            </Button>
            </div>
            </div>
          </div>
        );
      },
    });
    

    function logOutAcc() {
      setAuth(false)
    }
  }


  function delProfile(row) {
    const num1 = getNumerRound();
    const num2 = getNumerRound();
    let sum = 0

    confirmAlert({
      customUI: ({ onClose }) => {
      
        return (
          <div className='react-confirm-alert'>
            <div className="react-confirm-alert-body">
            <h1>Proszę potwierdzić</h1>
            <p>Czy chcesz skasować profil?</p>
     
           
            <Input
            label={`Ile wynosi ${num1} + ${num2} = `}
              type="number"
              autoFocus
              onChange={(e) => sum = (e.target.value)}
            />
           
            <div className="react-confirm-alert-button-group">
            <Button
              onClick={() => {
                onClose();
              }}
            >
              Zamknij
            </Button>
            <Button
          
              onClick={() => {
                if (parseInt(sum) === num1 + num2) {
                  onClose();
                  delProfileAcc()
                } else {
                  addToast("Nieprawidłowa odpowiedź. Spróbuj ponownie.", { appearance: 'error' });
                }
              }}
            >
              Potwierdź
            </Button>
            </div>
            </div>
          </div>
        );
      },
    });

    function delProfileAcc() {
      ConnectWS("/delProfile", authContext.token, { profile_id: row.profile_id }, function (response, logout) {

        if (logout === 1) {
          addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
          setTimeout(() => {
            setAuth(false);
          }, 4000);

        } else if (response && response.status && Number(response.status) === 200) {

          addToast(response.data.message, { appearance: 'info' });
          getProfile()
          authContext.changeProfile(null)

        } else {
          addToast(response, { appearance: 'error' });
        }


      });
    }
  }


  return (
    <Segment basic textAlign="center" style={{padding:0,margin:0, width: "100%", height: "100%", maxHeight: "100%",position:"relative" }}>
      {authContext.profile ?
        <>
        
          <Grid   >
            <Grid.Row verticalAlign="middle" >
              <Grid.Column width={14}>
                <Header block>
                  <Grid  >
                    <Grid.Row stretched  >
                      <Grid.Column width={7}>
                        <figure className='imgProfile'><img style={{ marginLeft: 4 }} src={logobz} width={30} height={30} alt="zdjęcie profilowe" /><figcaption style={{ fontSize: 14 }}>{authContext.profile.name}</figcaption></figure>
                      </Grid.Column>
                      <Grid.Column textAlign="right" width={6}>
                      <AddSubstricption imgOff={true}/>
                      </Grid.Column>
                      <Grid.Column textAlign="right" width={3}>
                     
                        <Button size="mini" color="blue" onClick={() => authContext.changeProfile(null)}>ZMIEŃ PROFIL</Button>
                      </Grid.Column>
                      <Grid.Column width={1}>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Header>

              </Grid.Column>
              <Grid.Column width={2} >

                <Icon bordered className="cursorpointer" color="blue" onClick={() => delProfile(authContext.profile)} inverted name="trash" />

              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} style={{ height: "calc(100vh - 200px)", maxHeight: "calc(100vh - 200px)",minHeight:100 }}>
              <Grid.Column width={16} style={{height: "100%", maxHeight: "100%"}} >
              <MyPictureView getProfile={getProfile}/>
              
              </Grid.Column>
             
            </Grid.Row>
          </Grid>

        </>
        : addProfile ? <>
          <Header block>
            <Grid verticalAlign='middle' >
              <Grid.Row stretched  >
                <Grid.Column width={12}>
                  Dodaj profil
                </Grid.Column>

                <Grid.Column textAlign="right" width={4}>
             
                  <Button size="mini" color="blue" onClick={() => setaddProfile(false)}>ZMIEŃ PROFIL</Button>
                </Grid.Column>

              </Grid.Row>
            </Grid>
          </Header>
          <AddProfile setaddProfile={setaddProfile} getProfile={getProfile} />
        </> : <>
          <Header block>
            <Grid verticalAlign='middle' >
              <Grid.Row stretched  >

                <Grid.Column width={13}>
                  Wybierz profil
                </Grid.Column>

                <Grid.Column width={3}>
                  <Button size="mini" onClick={() => logOut()} color="blue">WYLOGUJ</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>

          <Grid divided verticalAlign='middle' >
            <Grid.Row stretched style={{ maxHeight: "100%" }}>
              <Grid.Column width={11} style={{ maxHeight: "100%" }}>
                <CardGroup centered>

                  {profiles.map((row, i) => <Card key={i} link style={{ width: 90 }} onClick={() => clikcProfile(row)}>
                    <Image style={{ padding: 10 }} src={row.url} wrapped ui={false} />
                    <CardContent style={{ padding: 5 }}>
                      <CardHeader style={{ fontSize: 11, color: row.profile_id === 0 ? "orange" : "" }}>{row.name}</CardHeader>
                    </CardContent>
                  </Card>)}


                </CardGroup>
              </Grid.Column>
              <Grid.Column width={5} style={{ maxHeight: "100%" }}>
                <AddSubstricption imgOff={false}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </>}
        
    </Segment>
  );
}

export default Dashboard;
