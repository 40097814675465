import React, { useEffect, useRef, useState } from 'react';
import brush from "../assets/img/brush2.png";
import './AnimatedBrush.css';
import fanfary from "../assets/mp3/fanfary.mp3";
import brushteeth from "../assets/mp3/brushteeth.mp3";

import {  Icon } from 'semantic-ui-react';

const AnimatedBrush = ({ imageBrush, timerAnimation }) => {
  const [progress, setProgress] = useState(0);
  const animationDuration = timerAnimation * 60 * 1000; // 3 minuty w milisekundach
  const [isFinish, setisFinish] = useState(false);


  const myRef = useRef();
  const myRefFinish = useRef();

  function start() {
    try {
      myRefFinish.current.play();
    } catch (e) {
      console.log(e)
    }
  }

  function startbrushteeth() {
    try {
      myRef.current.play();
      myRef.current.loop = true;
    } catch (e) {
      console.log(e)
    }
  }

  function stopbrushteeth() {
    try {
      myRef.current.pause();
    } catch (e) {
      console.log(e)
    }
  }





  useEffect(() => {

    startbrushteeth()

    const intervalTime = 16; // Czas interwału, dostosuj go do swoich potrzeb
    const totalFrames = Math.ceil(animationDuration / intervalTime);
    let currentFrame = 0;

    let currsec = 2;

    const updateProgress = () => {

      const newProgress = (currentFrame / totalFrames) * 100;

      if (newProgress <= 100) {
        setProgress(newProgress);
        currentFrame += 1;

      } else {

        clearInterval(intervalId);
        clearInterval(intervalId2);
        setisFinish(true)

      }

    };

    const updateProgress2 = () => {

      if (((60 * timerAnimation) - currsec) === 8) {
        stopbrushteeth()
      }
      if (((60 * timerAnimation) - currsec) === 6) {
        start()
      }
      currsec += 1;

    };

    const intervalId = setInterval(updateProgress, intervalTime);
    const intervalId2 = setInterval(updateProgress2, 1000);

    return () => {
      clearInterval(intervalId);
      clearInterval(intervalId2);// Wyczyść interwał przy rozmontowywaniu komponentu
    };
  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  function getTimeValue(progress) {
    let totalSeconds = (60 * timerAnimation) - Math.ceil(progress / 100 * (60 * timerAnimation))

    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = (totalSeconds % 60);
    if (seconds < 10) {
      seconds = "0" + seconds;
    }


    return <><Icon name="clock" /> {minutes} : {seconds}</>
  }


  return (

    <div className="containerAnimation">
      <audio
        ref={myRefFinish}
        src={fanfary}
      />
      {isFinish ? <>

        <div className='textFinish'>
          GRATULACJE
        </div>
        <img
          className="background-imageFinishBIG"
          src={imageBrush}
          alt="Animated BackgroundBIG"

        />
        <img
          className="background-imageFinish"
          src={imageBrush}
          alt="Animated Background"
          style={{ opacity: "1", zIndex: "1" }}
        />
      </> : <>
        <audio
          ref={myRef}
          src={brushteeth}
        />
        <div className="overlay" style={{ left: `${progress - 4}%`, right: `${-progress}%` }}>

          <div className="moving-div">
            <img className="moving-image" style={{ width: 300 }} src={brush} alt="Bursh" />
          </div>

        </div>
        <img
          className="background-imageBIG"
          src={imageBrush}
          alt="Animated BackgroundBiG"

        />
        <img
          className="background-image"
          src={imageBrush}
          alt="Animated Background"

        />
        <div className="timer">{getTimeValue(progress)}</div>
      </>}

      <script>

      </script>
    </div>
  );
};

export default AnimatedBrush;
