import { useContext } from "react";
import AuthContext from "../context/authContext";
import { SCezarencrypt } from "../myfunctions/myfunctions";

export default function useAuth(){
    const authContext = useContext(AuthContext);
    const auth=authContext.isAuthenticated;
    const setAuth = (isAuthenticated,tokenData = null,userInfo=null) => {
        if(isAuthenticated){
              
            if(tokenData){
                window.localStorage.setItem('token',tokenData);    
            } 
            if(userInfo){      
                window.localStorage.setItem('userInfo',SCezarencrypt(userInfo));            
            } 
            authContext.login(); 
            authContext.changeView("Dashboard")
            
        }else{
            authContext.logout();
            authContext.changeToken(null)
            window.sessionStorage.clear();
            window.localStorage.clear();
            authContext.changeView("StartPage")
           
            setTimeout(() => {
                authContext.changeRefAllView(authContext.refAllView+1)
              }, 200);
        }
            
    }
    return [auth,setAuth]
}