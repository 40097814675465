import * as React from "react";
import { useToasts } from "react-toast-notifications";

// Taken from: https://gist.github.com/rikukissa/cb291a4a82caa670d2e0547c520eae53
export function useAddToHomescreenPrompt() {
  const [prompt, setState] = React.useState(null);
  const { addToast } = useToasts();
  const promptToInstall = () => {
    try {
      if (prompt) {
        return prompt.prompt();
      }
      addToast('Nie można automatycznie potwierdzić zainstalowania aplikacji. Proszę sprawdzić, czy aplikacja jest już zainstalowana. W przypadku jej braku, konieczne będzie skorzystanie z obecnej wersji przeglądarkowej.', { appearance: 'info' });
      return null
    } catch (e) {
      console.log(e)
      return null
    }
  };

  React.useEffect(() => {
    const ready = (e) => {
      e.preventDefault();
      setState(e);
    };

    window.addEventListener("beforeinstallprompt", ready);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
    };
  }, []);

  return [prompt, promptToInstall];
}