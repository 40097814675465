import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const MyImageLazy = ( image ) => (

  image.className ? 
    <LazyLoadImage
      alt={image.alt}
      src={image.src} // use normal <img> attributes as props
      //width={image.width}
      effect="blur" className={image.className}  />
      :
      <LazyLoadImage
      alt={image.alt}
      style={{maxHeight:image.maxHeight}}
      height={image.height}
      src={image.src} // use normal <img> attributes as props
      //width={image.width}
      effect="blur"  />

);

export default MyImageLazy;