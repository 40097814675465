
import React, { useReducer } from "react";
import { Header, Icon, Segment } from "semantic-ui-react";
import AuthContext from './context/authContext';
import { CookiesProvider } from 'react-cookie';
import { ErrorBoundary } from 'react-error-boundary';
import useScreenOrientation from 'react-hook-screen-orientation'
import RotateScreen from "./RotateScreen";
import { ToastProvider } from "react-toast-notifications";
import RouterPage from "./RouterPage";
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css



const reducer = (state, action) => {
  switch (action.type) {
    case 'set-isAuthenticated': return { ...state, isAuthenticated: action.isAuthenticated };
    case 'set-characterSelected': return { ...state, characterSelected: action.characterSelected };
    case 'set-UserInfo': return { ...state, userInfo: action.userInfo };
    case 'set-ShowToken': return { ...state, token: action.token };
    case 'set-changeView': return { ...state, gView: action.gView };
    case 'set-changeProfile': return { ...state, profile: action.profile };
    case 'set-refAllView': return { ...state, refAllView: action.refAllView };
    
    case 'login': return { ...state, isAuthenticated: true };
    case 'logout': return { ...state, isAuthenticated: false };

    default: console.log('Sorry');
  }
  return state;
}

const initState = {
  isAuthenticated: false,
  characterSelected: null,
  userInfo: "",
  token: null,
  gView: "StartPage",
  profile: null,
  refAllView:0,
};


function ErrorFallbackApp({ error, resetErrorBoundary }) {
  return (
    <Segment placeholder style={{ height: "100vh" }}>

      <Header icon>

        <Icon name='warning sign' />
        Napotkaliśmy problem - odśwież stronę.

        <Header.Subheader>
          Jeśli ten problem będzie się powtarzać, skontaktuj się z administratorem systemu aby uzyskać więcej informacji.
        </Header.Subheader>

      </Header>

    </Segment>
  )
}

function App() {


  const [state, dispatch] = useReducer(reducer, initState);
  const screenOrientation = useScreenOrientation()






  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackApp}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}>
      <CookiesProvider>
        <Router>
          <AuthContext.Provider value={{
            isAuthenticated: state.isAuthenticated,
            characterSelected: state.characterSelected,
            userInfo: state.userInfo,
            token: state.token,
            gView: state.gView,
            profile: state.profile,
            refAllView: state.refAllView,
            login: () => dispatch({ type: 'login' }),
            logout: () => dispatch({ type: 'logout' }),
            changeUserInfo: (val) => dispatch({ type: 'set-UserInfo', userInfo: val }),
            changeView: (val) => dispatch({ type: 'set-changeView', gView: val }),
            changeProfile: (val) => dispatch({ type: 'set-changeProfile', profile: val }),
            changecharacterSelected: (val) => dispatch({ type: 'set-characterSelected', characterSelected: val }),
            changeRefAllView: (val) => dispatch({ type: 'set-refAllView', refAllView: val }),
            changeToken: (val) => dispatch({ type: 'set-ShowToken', token: val }),

          }}>
            <ToastProvider autoDismiss autoDismissTimeout={2000}>{
              <div className="preStart">
                {screenOrientation === "portrait-primary" ? <RotateScreen /> : ""}

                <RouterPage />
              </div>
            }</ToastProvider>
          </AuthContext.Provider>
        </Router>
      </CookiesProvider>
    </ErrorBoundary>
  );
}

export default App;
